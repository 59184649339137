<template>
    <div class="client-mood-history d-flex flex-column">
        <div class="clinical-counselors-header d-flex gap-2 align-items-center justify-content-center">
            <div class="flex-1 flex align-items-center gap-4">
                <h3>Mood History</h3>
                <div
                    class="d-flex gap-4 bg-white align-items-center px-4 py-2 rounded-5"
                    style="color: #979699; font-weight: 600; font-size: 1rem"
                >
                    <span class="flex gap-2 align-items-center"
                        ><span class="color-indicator" style="background-color: #aac832"></span>Pleasant</span
                    >
                    <span class="flex gap-2 align-items-center"
                        ><span class="color-indicator" style="background-color: #cb177d"></span> Neutral</span
                    >
                    <span class="flex gap-2 align-items-center"
                        ><span class="color-indicator" style="background-color: #f58220"></span>Negative</span
                    >
                </div>
            </div>
            <div class="m-2" ref="applicationDropdownContainer">
                <button class="btn dropdown-btn" @click="showApplicationDropdown = !showApplicationDropdown">
                    {{ formatPortalName(selectedApplication) }}
                    <span class="material-icons-outlined">expand_more</span>
                </button>
                <ul v-if="showApplicationDropdown" class="dropdown-container" style="min-width: 13rem">
                    <li v-for="(item, index) in applications" :key="index" @click="handleApplicationDropdown(item)">
                        {{ formatPortalName(item) }}
                    </li>
                </ul>
            </div>
            <div class="m-2" ref="dateDropdownContainer">
                <button class="btn dropdown-btn" @click="showDropdown = !showDropdown">
                    {{ selectedOption }}
                    <span class="material-icons-outlined">expand_more</span>
                </button>
                <ul v-if="showDropdown" class="dropdown-container">
                    <li v-for="(item, index) in calendarOption" :key="index" @click="handleHistoryPeriod(item)">
                        {{ item }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="mood-history-table">
            <div class="mood-history-header w-100">
                <span class="dark-text weight-600" style="width: 10%">Feeling</span>
                <span class="dark-text weight-600" style="width: 25%">Reason</span>
                <span class="dark-text weight-600" style="width: 15%">Date</span>
                <span class="dark-text weight-600" style="width: 50%">Note</span>
            </div>
            <template v-if="!loading">
                <div v-if="row_data.length" class="mood-history-data w-100">
                    <div v-for="data in row_data" :key="data.id" class="mood-history-data-item mood-history-row">
                        <span style="width: 10%">
                            <span v-if="data.emoticons.length" style="font-size: 2rem">
                                {{ moodEmojiMap[data.emoticons[0]] }}</span
                            >
                        </span>
                        <span :id="`reason ${data.id}`" class="my-1 w-25">
                            <div
                                v-if="data.reasons.length"
                                class="flex flex-wrap align-items-center p-2"
                                :style="getReasonStyles(data.category)"
                                style="border-radius: 8px; color: #000000; width: fit-content"
                            >
                                {{ truncateReasons(data.reasons) }}
                            </div>
                        </span>
                        <b-tooltip
                            v-if="data.reasons.length > 3"
                            :target="`reason ${data.id}`"
                            :title="data.reasons.join(', ')"
                            triggers="hover"
                        />
                        <span style="width: 15%">{{ formattedDate(data.created_at) }}</span>
                        <span style="width: 50%; word-break: break-all">{{ data.notes }}</span>
                    </div>
                </div>
                <div v-else class="bg-white w-100">
                    <div class="d-grid justify-content-center align-items-center py-5" style="min-height: 6rem">
                        <h5 style="color: #979699">No History Available</h5>
                    </div>
                </div>
            </template>
            <div
                v-if="loading"
                class="d-flex flex-column justify-content-center align-items-center loader-main"
                style="min-height: 6rem"
            >
                <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
                <span class="ms-3">Loading ...</span>
            </div>
        </div>

        <DateRangeSelectorModal
            v-if="showDateRangeModal"
            :startDate="customStartDate"
            :endDate="customEndDate"
            @cancel="showDateRangeModal = false"
            @save="handleCustomDateRange"
        />
    </div>
</template>

<script>
    import { clients, dashboardBuilder } from '@/util/apiRequests';
    import { moodEmojiMap } from '@/util/globalConstants';
    import { formatPortalName } from '@/util/genericUtilityFunctions';
    import DateRangeSelectorModal from './DateRangeSelectorModal';
    import dayjs from '@/util/dayjs';

    const reasonColors = { negative: '#FFD9C9', pleasant: '#E4E9D7', neutral: '#FFAAD9' };

    export default {
        name: 'MoodHistory',
        components: { DateRangeSelectorModal },
        props: {
            client_id: {
                type: Number,
            },
        },
        data() {
            return {
                row_data: [],
                selectedApplication: 'mobex-health-home',
                showApplicationDropdown: false,
                showDropdown: false,
                applications: [],
                selectedOption: 'Weekly',
                calendarOption: ['Weekly', 'Monthly', 'Custom'],
                startDate: null,
                endDate: null,
                customStartDate: null,
                customEndDate: null,
                loading: true,
                showDateRangeModal: false,
                moodEmojiMap,
            };
        },
        methods: {
            formatPortalName(name) {
                return formatPortalName(name); 
            },
            async getAllApplications() {
                try {
                    const endpoint = dashboardBuilder.getAllApplications(this.client_id);
                    const response = await this.$api.get(endpoint);
                    this.applications = response?.data?.response;
                } catch (err) {
                    this.$toasted.error(err ?? 'Failed to fetch applications');
                }
            },
            async getMoodHistory() {
                try {
                    this.loading = true;
                    const body = {
                        type: 'Custom',
                        startDate: this.startDate,
                        endDate: this.endDate,
                    };

                    const endpoint = clients.getMoodHistory(this.selectedApplication, this.client_id);
                    const response = await this.$api.post(endpoint, body);

                    const data = response.data.message.reduceRight((acc, entry) => {
                        for (let date in entry) {
                            if (entry[date].length) {
                                acc = acc.concat(entry[date]);
                            }
                        }
                        return acc;
                    }, []);

                    this.row_data = data;
                } catch (err) {
                    this.$toasted.error(err ?? 'Failed to fetch Mood History details');
                } finally {
                    this.loading = false;
                }
            },
            getReasonStyles(category) {
                return {
                    backgroundColor: `${reasonColors[category]}40`,
                    border: `1px solid ${reasonColors[category]}`,
                };
            },
            truncateReasons(reasons) {
                return reasons.slice(0, 3).join(', ') + (reasons.length > 3 ? '...' : '');
            },
            formattedDate(date) {
                return date ? dayjs.utc(date).format('D MMM YYYY') : '';
            },
            handleApplicationDropdown(val) {
                if (val !== this.selectedApplication) {
                    this.selectedApplication = val;
                    this.getMoodHistory();
                }
                this.showApplicationDropdown = false;
            },
            async handleHistoryPeriod(period) {
                if (period === 'Custom') {
                    this.showDateRangeModal = true;
                    this.showDropdown = false;
                    return;
                }

                let endDate = dayjs(new Date()).format('YYYY-MM-DD');
                let startDate = null;

                if (period === 'Weekly') {
                    startDate = dayjs(endDate).utc().subtract(6, 'day').format('YYYY-MM-DD');
                } else if (period === 'Monthly') {
                    startDate = dayjs(endDate).utc().subtract(29, 'day').format('YYYY-MM-DD');
                }

                if (this.startDate !== startDate || this.endDate !== endDate) {
                    this.selectedOption = period;
                    this.startDate = startDate;
                    this.endDate = endDate;
                    await this.getMoodHistory();
                }

                this.showDropdown = false;
            },
            async handleCustomDateRange({ startDate, endDate }) {
                this.showDateRangeModal = false;
                this.selectedOption = 'Custom';
                this.startDate = dayjs(startDate).utc().format('YYYY-MM-DD');
                this.endDate = dayjs(endDate).utc().format('YYYY-MM-DD');

                this.customStartDate = this.startDate;
                this.customEndDate = this.endDate;

                await this.getMoodHistory();
            },
            handleClickOutside(event) {
                const applicationDropdownContainer = this.$refs.applicationDropdownContainer;
                const dateDropdownContainer = this.$refs.dateDropdownContainer;
                if (applicationDropdownContainer && !applicationDropdownContainer.contains(event.target)) {
                    this.showApplicationDropdown = false;
                }
                if (dateDropdownContainer && !dateDropdownContainer.contains(event.target)) {
                    this.showDropdown = false;
                }
            },
        },
        async created() {
            // Set default date
            this.endDate = dayjs(new Date()).format('YYYY-MM-DD');
            this.startDate = dayjs(this.endDate).utc().subtract(6, 'day').format('YYYY-MM-DD');

            await this.getAllApplications();
            await this.getMoodHistory();

            this.loading = false;
        },
        mounted() {
            document.addEventListener('click', this.handleClickOutside);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleClickOutside);
        },
    };
</script>

<style scoped>
    .color-indicator {
        min-width: 15px;
        min-height: 15px;
        border-radius: 50%;
        background-color: red;
    }

    .dropdown-btn,
    .dropdown-btn:focus {
        border-radius: 20px;
        border: 1px solid #adaeb1;
        color: #979699;
        font-weight: 600;
        font-size: 1rem;
        min-width: 8rem;
        text-transform: capitalize;
    }

    .dropdown-container {
        position: absolute;
        background-color: white;
        font-weight: 600;
        color: #979699;
        font-size: 13px;
        padding-top: 10px;
        border-radius: 10px;
        min-width: 8rem;
        cursor: pointer;
        box-shadow: 0px 1px 6px rgba(58, 64, 82, 0.15);
        border-radius: 5px;
        overflow: hidden;
        list-style: none;
    }

    ul {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    ul li {
        padding-left: 1rem;
        border-radius: 0.5rem;
        text-transform: capitalize;
    }

    .dropdown-container li:hover {
        background-color: #f8f7fa;
    }
</style>
