<template>
    <div class="page-body date-range-selector">
        <NoButtonModal :open="true">
            <div class="modal-content-header">
                <span style="font-size: 20px; font-weight: bold; margin-left: 20px"> Select Custom Date Range </span>
            </div>
            <div class="d-flex flex-column gap-3" style="padding: 2rem">
                <span style="font-size: 1.2rem">Please select a date range to view custom period data</span>
                <a-range-picker v-model="dateRange" format="YYYY-MM-DD" :disabled-date="disabledDate" class="w-full" />
            </div>
            <div class="mx-4 d-flex justify-content-end" style="bottom: 10%">
                <button type="button" class="no-bg btn-cancel px-4" @click="$emit('cancel')">Cancel</button>
                <button type="button" class="primary btn-save" @click="save">Apply</button>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { DatePicker } from 'ant-design-vue';
    import moment from 'moment';
    import 'ant-design-vue/dist/antd.css';

    export default {
        name: 'DateRangeSelectorModal',
        components: { NoButtonModal, 'a-range-picker': DatePicker.RangePicker },
        props: {
            startDate: {
                type: String,
                default: null,
            },
            endDate: {
                type: String,
                default: null,
            },
        },
        data() {
            return {
                dateRange:
                    this.startDate !== null && this.endDate !== null
                        ? [moment(this.startDate), moment(this.endDate)]
                        : [],
            };
        },
        methods: {
            disabledDate(date) {
                return date && date.isAfter(moment(), 'day');
            },
            save() {
                if (!this.dateRange || this.dateRange.length !== 2) {
                    this.$toasted.error('Please select a valid date range');
                    return;
                }
                const [start, end] = this.dateRange;
                this.$emit('save', { startDate: start.valueOf(), endDate: end.valueOf() });
            },
        },
    };
</script>

<style scoped>
    .modal-content-header {
        background-color: #f1f7ff;
        padding: 10px;
        display: flex;
        align-items: center;
    }

    .btn-save {
        border-radius: 10px;
        background: #5155c3 !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
    }

    .btn-cancel,
    .btn-cancel:hover {
        border-radius: 10px;
        border: 2px solid #ac2734;
    }
</style>

<style>
    .date-range-selector .modal__content {
        padding: 0;
        border-radius: 10px;
        min-height: fit-content;
    }

    .date-range-selector .modal__content input:not([class='multiselect__input']) {
        box-shadow: none;
    }
</style>
